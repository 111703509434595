import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  useNavigation,
  useRoute,
  useFocusEffect,
  useIsFocused,
} from '@react-navigation/native';
import { NativeHeader } from 'assets/components/native-header';
import { CloseIcon } from 'assets/icons';
import { AppointmentsScreenRouteProp } from '../../navigation/RootNavigation';
import {
  getAppointmentType,
  getBooking,
  setAppointmentType,
  setBooking,
  setRefresh,
  updateBooking,
  setFormSubmissions,
  setCurrentForm,
  setSaveSubmissionsStatus,
  updateBookingOptimistically,
  cancelBooking,
} from './appointment-details-actions';
import { useAppStateStore } from '../../store/app-store';
import { useAppointmentDetailsState } from './appointment-details-store';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { Platform, View } from 'react-native';
import { Text } from 'assets/components/text';
import { formatDateTimeWithTimezone } from '../../common/datetime-utils';
import { DEFAULT_UTC_OFFSET } from '../appointments/book-appointment/book-appointment-utils';
import { Button } from 'assets/components/button';
import { BottomSheetModal, TouchableOpacity } from '@gorhom/bottom-sheet';
import { makeStyles, useTheme } from 'assets/theme';
import { AppointmentDetailsInfo } from './AppointmentDetailsInfo';
import { ScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import {
  setShowBookAppointment,
  setAppointmentType as setAppointmentTypeBooking,
  setIsReschedule,
  setBooking as setModalBooking,
  setSelectedDate,
  setSelectedPatient,
  setLocationOverride,
} from '../appointments/book-appointment/book-appointment-actions';
import moment from 'moment';
import { BookAppointment } from '../appointments/book-appointment/BookAppointment';
import {
  CancelAppointment,
  CancelAppointmentHandler,
} from './cancel-appointment/CancelAppointment';
import {
  BookingDto,
  SubmissionDto,
  UpdateBookingDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import {
  AppointmentDetailsBadge,
  AppointmentStatus,
} from './AppointmentDetailsBadge';
import patientService from '../../api/patient-service';
import { logError } from 'assets/logging/logger';
import {
  PatientRecordDto,
  RecordUnderCareDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { CheckboxInput, CheckboxInputMode } from 'assets/components/checkbox';
import { FormStatus } from '@digitalpharmacist/forms-service-client-axios';
import { AppointmentChecklistBadge } from './AppointmentChecklistBadge';
import { Modal } from '../../../../../packages/assets/components/modal';
import { BottomSheet } from '../../../../../packages/assets/components/bottom-sheet';
import { IconButton } from '../../../../../packages/assets/components/icon-button';
import {
  CloseConfirmation,
  CloseConfirmationHandler,
} from '../appointments/book-appointment/CloseConfirmation';
import { FillInFormContent } from '../fill-in-form/FillInFormContent';
import {
  CancelAppointmentResult,
  CancelAppointmentResultHandler,
} from './cancel-appointment/CancelAppointmentResult';

export const AppointmentDetails: FunctionComponent<AppointmentDetailsProps> = (
  props,
) => {
  const navigation = useNavigation<AppointmentsScreenRouteProp>();
  const route = useRoute<any>();
  const appointmentIdParam = route.params.appointment_id;
  const locationIdParam = route.params.location_id;
  const isFocused = useIsFocused();
  const { pharmacyId, appointmentLocation } = useAppStateStore((state) => ({
    ...state,
    appointmentLocation: state.stores.find(
      (store) => store.id === locationIdParam,
    ),
  }));
  const {
    booking,
    appointmentType,
    type,
    status,
    appointmentTypeStatus,
    refresh,
    checkListStatus,
    currentForm,
    formSubmissions,
    saveSubmissionsStatus,
  } = useAppointmentDetailsState();

  const { validForms, validFormIds, validFormsCount } =
    useAppointmentDetailsState((state) => ({
      validForms: state.appointmentType?.forms.filter(
        (form) => form.form_status === FormStatus.Enabled,
      ),
      validFormsCount:
        state.appointmentType?.forms.filter(
          (form) => form.form_status === FormStatus.Enabled,
        ).length ?? 0,
      validFormIds: state.appointmentType?.forms
        .filter((form) => form.form_status === FormStatus.Enabled)
        .map((form) => form.form_id),
    }));

  const styles = useStyles();
  const theme = useTheme();
  const [patient, setPatient] = useState<
    PatientRecordDto | RecordUnderCareDto
  >();
  const [patientLoading, setPatientLoading] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [locationPatientRecordId, setLocationPatientRecordId] =
    useState<string>();
  const sheetRef = React.useRef<BottomSheetModal>(null);
  const closeConfirmationRef = React.useRef<CloseConfirmationHandler>(null);
  const cancelAppointmentRef = React.useRef<CancelAppointmentHandler>(null);
  const cancelAppointmentResultRef =
    React.useRef<CancelAppointmentResultHandler>(null);
  const [closeButtonDisabled, setCloseButtonDisabled] = useState<boolean>(true);

  const formsCompleted =
    validFormsCount === 0 ||
    (validFormIds &&
      validFormsCount > 0 &&
      booking &&
      booking.submissions.length > 0 &&
      booking.submissions.every((sub) => validFormIds.includes(sub.form_id)));

  const isChecklistComplete = !!(
    booking?.bring_insurance_card &&
    booking.review_related_health_info &&
    formsCompleted
  );

  useFocusEffect(
    useCallback(() => {
      getBookingData();

      return () => {
        setBooking(undefined);
        setAppointmentType(undefined);
      };
    }, [route]),
  );

  useEffect(() => {
    if (booking && checkListStatus !== 'loading') {
      setPatientLoading(true);

      getAppointmentType(
        pharmacyId,
        booking.location_id,
        booking.appointment_type_id,
      );

      patientService
        .findPatientRecord(booking.patient_record_id)
        .then((patientRecord) => {
          setPatient(patientRecord);
        })
        .catch((error) => logError(error))
        .finally(() => setPatientLoading(false));
    }
  }, [booking]);

  useEffect(() => {
    if (refresh) {
      getBookingData();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (patient) {
      setLocationPatientRecordId(
        patient.location_patient_records.find(
          (record) => record.location_id === booking?.location_id,
        )?.id,
      );
    }
  }, [patient]);

  useEffect(() => {
    Platform.OS === 'web' && window.addEventListener('message', handleMessage);

    return () => {
      Platform.OS === 'web' &&
        window.removeEventListener('message', handleMessage);
    };
  }, [currentForm, appointmentType]);

  const handleMessage = (event: MessageEvent) => {
    if (event.data.action !== 'form-submitted') return;

    handleFormSubmit(event.data.submissionId);
  };

  const getBookingData = () => {
    if (appointmentIdParam && locationIdParam) {
      getBooking(pharmacyId, locationIdParam, appointmentIdParam);
    }
  };

  const handleReschedule = () => {
    setBookAppointmentValues();
    setShowBookAppointment(true);
  };

  const setBookAppointmentValues = () => {
    setAppointmentTypeBooking(appointmentType);
    setIsReschedule(true);
    setModalBooking(booking);
    setSelectedDate(moment(booking?.startTime).format('YYYY-MM-DD'));
    setSelectedPatient(patient);
    setLocationOverride(appointmentLocation);
  };

  const handleUpdateBooking = (field: Record<string, boolean>) => {
    if (!booking || !locationIdParam) return;

    void updateBookingOptimistically(
      pharmacyId,
      locationIdParam,
      booking.id,
      field,
    );
  };

  const getStatus = (booking: BookingDto): AppointmentStatus => {
    if (booking.status === 'CANCELLED') {
      return 'canceled';
    }

    if (type === 'past') {
      return 'past';
    }

    if (moment(booking.startTime).isSame(moment(), 'day')) {
      return 'today';
    }

    if (moment(booking.startTime).isSame(moment().add(1, 'day'), 'day')) {
      return 'tomorrow';
    }

    return 'upcoming';
  };

  const handleConfirmClose = () => {
    setCurrentForm(0);
    setFormSubmissions([]);
    setCloseButtonDisabled(true);
    Platform.OS === 'web' ? setModalIsOpen(false) : sheetRef.current?.dismiss();
  };

  const handleFormsDismiss = () => {
    if (!isSaved) {
      closeConfirmationRef.current?.show();
    }
  };

  const handleFormSubmit = (submissionId: string) => {
    if (submissionId) {
      const submissions = [
        ...formSubmissions,
        {
          submission_id: submissionId,
          form_id: validForms![currentForm].form_id,
        },
      ];
      setFormSubmissions(submissions);

      if (validFormsCount > currentForm + 1) {
        setCurrentForm(currentForm + 1);
      } else {
        handleSubmissionsSave(submissions);
      }
    }
  };

  const handleSubmissionsSave = (submissions: SubmissionDto[]) => {
    if (booking) {
      setSaveSubmissionsStatus('loading');

      const bookingData: UpdateBookingDto = {
        ...booking,
        submissions: submissions,
      };

      updateBooking(
        pharmacyId,
        booking.location_id,
        booking.id,
        bookingData,
      ).finally(() => {
        setSaveSubmissionsStatus('idle');
        setIsSaved(true);
        handleConfirmClose();
      });
    }
  };

  const getFormsModalTitle = () => {
    return `${appointmentType?.title} (${currentForm + 1} ${getText(
      'of',
    )} ${appointmentType?.forms.length})`;
  };

  const isFormsLinkEnabled = (booking: BookingDto) => {
    return !formsCompleted && booking.status !== 'CANCELLED' && type !== 'past';
  };

  const handleCancel = (booking: BookingDto) => {
    cancelBooking(pharmacyId, booking.location_id, booking.id).finally(() => {
      cancelAppointmentRef.current?.hide();
      cancelAppointmentResultRef.current?.show();
    });
  };

  return (
    <>
      <NativeHeader
        actionIcon={CloseIcon}
        onBack={() => navigation.navigate('appointments')}
        showAction={false}
        showBack={true}
        title={'Appointment details'}
      />
      <ScreenContainer>
        {isFocused && (
          <BookAppointment onDismiss={() => setShowBookAppointment(false)} />
        )}

        {status === 'loading' || !booking ? (
          <View style={{ marginTop: theme.getSpacing(4) }}>
            <LoadingIndicator />
          </View>
        ) : (
          <>
            <CancelAppointment
              ref={cancelAppointmentRef}
              onCancel={() => handleCancel(booking)}
            />
            <CancelAppointmentResult
              ref={cancelAppointmentResultRef}
              booking={booking}
            />
            <View style={styles.titleContainer}>
              <Text style={styles.title}>{booking.title}</Text>

              <AppointmentDetailsBadge status={getStatus(booking)} />
            </View>
            {type === 'upcoming' && booking.status !== 'CANCELLED' ? (
              <AppointmentChecklistBadge
                loading={
                  !appointmentLocation || appointmentTypeStatus === 'loading'
                }
                complete={isChecklistComplete}
              />
            ) : null}
            <Text style={styles.description}>{booking.description}</Text>
            <AppointmentDetailsInfo label={getText('when')}>
              <Text style={styles.infoText}>
                {formatDateTimeWithTimezone(
                  booking.startTime,
                  DEFAULT_UTC_OFFSET,
                  'dddd, MMMM D, YYYY',
                )}
              </Text>
              <Text style={styles.infoText}>
                {formatDateTimeWithTimezone(
                  booking.startTime,
                  DEFAULT_UTC_OFFSET,
                  'hh:mm A',
                )}{' '}
                -{' '}
                {formatDateTimeWithTimezone(
                  booking.endTime,
                  DEFAULT_UTC_OFFSET,
                  'hh:mm A',
                )}{' '}
                CT
              </Text>
            </AppointmentDetailsInfo>
            <AppointmentDetailsInfo label={getText('who')}>
              <Text style={styles.infoText}>
                {booking.patient_record_first_name}{' '}
                {booking.patient_record_last_name}
              </Text>
            </AppointmentDetailsInfo>
            <AppointmentDetailsInfo label={getText('where')}>
              {!appointmentLocation ? (
                <LoadingIndicator size={24} />
              ) : (
                <>
                  <Text style={styles.infoText}>
                    {appointmentLocation.name}
                  </Text>
                  {appointmentLocation.address?.address1 && (
                    <>
                      <Text
                        style={[
                          styles.infoText,
                          { color: theme.palette.gray[500] },
                        ]}
                      >
                        {appointmentLocation.address.address1}
                        {appointmentLocation.address.address2 &&
                          `, ${appointmentLocation.address.address2}`}
                      </Text>
                      <Text
                        style={[
                          styles.infoText,
                          { color: theme.palette.gray[500] },
                        ]}
                      >
                        {appointmentLocation.address.city},{' '}
                        {appointmentLocation.address.state}{' '}
                        {appointmentLocation.address.postal_code}
                      </Text>
                    </>
                  )}
                </>
              )}
            </AppointmentDetailsInfo>
            <AppointmentDetailsInfo
              label={getText('pre-appointment-checklist')}
              containerStyle={styles.checkboxContainer}
              labelStyle={styles.checkboxLabel}
            >
              {!appointmentLocation || appointmentTypeStatus === 'loading' ? (
                <LoadingIndicator size={24} />
              ) : (
                <View style={styles.checkboxContentContainer}>
                  {validFormsCount && validFormsCount > 0 ? (
                    <View
                      style={{ flexDirection: 'row', alignItems: 'center' }}
                    >
                      <CheckboxInput
                        checked={formsCompleted}
                        onPress={(checked) => null}
                        mode={CheckboxInputMode.FLAT}
                        disabled
                      />
                      <TouchableOpacity
                        onPress={() =>
                          Platform.OS === 'web'
                            ? setModalIsOpen(true)
                            : sheetRef.current?.present()
                        }
                        disabled={!isFormsLinkEnabled(booking)}
                      >
                        <Text
                          style={[
                            styles.formsCheckboxLabel,
                            isFormsLinkEnabled(booking) && {
                              color: theme.palette.primary[600],
                            },
                          ]}
                        >
                          {formsCompleted
                            ? getText('forms-filled-in')
                            : getText('forms-fill-out', {
                                formsCount: validFormsCount,
                              })}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : null}
                  <CheckboxInput
                    checked={!!booking.review_related_health_info}
                    onPress={(checked) =>
                      handleUpdateBooking({
                        review_related_health_info: checked,
                      })
                    }
                    label={getText('health-info')}
                    mode={CheckboxInputMode.FLAT}
                    disabled={
                      checkListStatus === 'loading' ||
                      type !== 'upcoming' ||
                      booking.status === 'CANCELLED'
                    }
                  />

                  <CheckboxInput
                    checked={!!booking.bring_insurance_card}
                    onPress={(checked) =>
                      handleUpdateBooking({
                        bring_insurance_card: checked,
                      })
                    }
                    label={getText('insurance-card-reminder')}
                    mode={CheckboxInputMode.FLAT}
                    disabled={
                      checkListStatus === 'loading' ||
                      type !== 'upcoming' ||
                      booking.status === 'CANCELLED'
                    }
                  />
                </View>
              )}
            </AppointmentDetailsInfo>
            {!appointmentType ? (
              <LoadingIndicator size={24} />
            ) : (
              <>
                {appointmentType.notes && (
                  <>
                    <View style={styles.notesTitleContainer}>
                      <Text style={styles.notesTitle}>
                        {getText('care-notes')}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.notes}>{appointmentType.notes}</Text>
                    </View>
                  </>
                )}
              </>
            )}
            {type === 'upcoming' && booking.status !== 'CANCELLED' && (
              <View style={styles.buttonsContainer}>
                <Button
                  hierarchy="tertiary-gray"
                  size="medium"
                  onPress={handleReschedule}
                  logger={{ id: `appointment-reschedule-${booking.id}` }}
                  loading={patientLoading}
                  disabled={patientLoading}
                >
                  {getText('reschedule')}
                </Button>
                <TouchableOpacity
                  onPress={() => cancelAppointmentRef.current?.show()}
                >
                  <View style={styles.cancelButtonContainer}>
                    <Text style={styles.cancelButtonText}>
                      {getText('cancel-appointment')}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            )}

            {validFormsCount ? (
              <>
                {Platform.OS === 'web' ? (
                  <Modal
                    title={getFormsModalTitle()}
                    show={modalIsOpen}
                    okButtonProps={{
                      onPress: handleConfirmClose,
                      logger: { id: 'appointment-forms-ok-button-modal' },
                      disabled: closeButtonDisabled,
                      loading: saveSubmissionsStatus === 'loading',
                    }}
                    dismissButtonProps={{
                      onPress: handleFormsDismiss,
                      logger: { id: 'appointment-forms-dismiss-button-modal' },
                    }}
                    cancelButtonProps={{
                      onPress: handleFormsDismiss,
                      logger: { id: 'appointment-forms-cancel-button-modal' },
                    }}
                    isScrollable={true}
                    height={800}
                    scrollViewStyle={{ flex: 1 }}
                    contentContainerStyle={{ flex: 1 }}
                  >
                    {locationPatientRecordId && validForms ? (
                      <FillInFormContent
                        formId={validForms[currentForm].form_id}
                        locationId={booking.location_id}
                        locationPatientRecordId={locationPatientRecordId}
                        onFormSubmit={handleFormSubmit}
                      />
                    ) : null}

                    <CloseConfirmation
                      ref={closeConfirmationRef}
                      onConfirm={handleConfirmClose}
                    />
                  </Modal>
                ) : (
                  <BottomSheet
                    height="100%"
                    bottomSheetRef={sheetRef}
                    onDismiss={handleFormsDismiss}
                    contentContainerStyle={{ flex: 1 }}
                    hideHandle={true}
                    headerContent={
                      <View
                        style={{
                          position: 'relative',
                          marginBottom: theme.getSpacing(1),
                        }}
                      >
                        <View style={styles.sheetIconContainer}>
                          <IconButton
                            icon={CloseIcon}
                            onPress={handleFormsDismiss}
                            size={24}
                            color={theme.palette.gray[500]}
                            logger={{
                              id: 'appointment-forms-bottom-sheet-close',
                            }}
                          />
                        </View>
                        <View style={styles.sheetTitleContainer}>
                          <Text style={styles.sheetTitle}>
                            {getFormsModalTitle()}
                          </Text>
                        </View>
                      </View>
                    }
                    children={
                      <>
                        {locationPatientRecordId && validForms ? (
                          <FillInFormContent
                            formId={validForms[currentForm].form_id}
                            locationId={booking.location_id}
                            locationPatientRecordId={locationPatientRecordId}
                            onFormSubmit={handleFormSubmit}
                          />
                        ) : null}
                        <CloseConfirmation
                          ref={closeConfirmationRef}
                          onConfirm={handleConfirmClose}
                        />
                      </>
                    }
                    footerContent={
                      <Button
                        hierarchy="primary"
                        size="medium"
                        onPress={handleConfirmClose}
                        logger={{
                          id: `appointment-forms-ok-button-bottom-sheet`,
                        }}
                        disabled={closeButtonDisabled}
                        loading={saveSubmissionsStatus === 'loading'}
                        style={{
                          marginBottom: theme.getSpacing(2),
                        }}
                      >
                        {getText('confirm')}
                      </Button>
                    }
                  />
                )}
              </>
            ) : null}
          </>
        )}
      </ScreenContainer>
    </>
  );
};

export interface AppointmentDetailsProps {}

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: theme.getSpacing(3),
    marginTop: theme.getSpacing(2),
  },
  title: {
    fontSize: 24,
    lineHeight: 32,
    color: theme.palette.gray[900],
    maxWidth: '75%',
  },
  label: {
    paddingHorizontal: theme.getSpacing(1),
    lineHeight: 26,
    marginLeft: theme.getSpacing(2),
    borderRadius: theme.roundness,
    marginTop: theme.getSpacing(0.5),
  },
  labelUpcoming: {
    backgroundColor: theme.palette.success[50],
    color: theme.palette.success[700],
  },
  labelPast: {
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[700],
  },
  description: {
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(2),
  },
  infoText: {
    fontSize: 16,
    color: theme.palette.gray[700],
  },
  notesTitleContainer: {
    paddingBottom: theme.getSpacing(1),
    borderColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    marginVertical: theme.getSpacing(2),
  },
  notesTitle: {
    color: theme.palette.gray[900],
    fontSize: 16,
    lineHeight: 24,
  },
  notes: {
    color: theme.palette.gray[500],
    fontSize: 16,
  },
  buttonsContainer: {
    marginTop: 'auto',
  },
  cancelButtonContainer: {
    paddingVertical: theme.getSpacing(1),
    marginVertical: theme.getSpacing(1),
  },
  cancelButtonText: {
    ...theme.fonts.medium,
    color: theme.palette.error[600],
    textAlign: 'center',
  },
  checkboxContainer: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginTop: theme.getSpacing(2),
  },
  checkboxContentContainer: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: 120,
  },
  checkboxLabel: {
    width: '100%',
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    paddingBottom: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
  formsCheckboxLabel: {
    fontSize: 16,
    color: theme.palette.gray[700],
    marginLeft: theme.getSpacing(1),
  },
  sheetIconContainer: {
    position: 'absolute',
    left: -12,
    top: -5,
    zIndex: 1,
  },
  sheetTitleContainer: {
    paddingHorizontal: theme.getSpacing(3),
    paddingVertical: theme.getSpacing(1),
  },
  sheetTitle: {
    ...theme.fonts.medium,
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 18,
  },
}));
