import { getText } from 'assets/localization/localization';
import { Order } from './types';

export const sortingOptions: any[] = [
  {
    label: getText('newest'),
    value: 'newest',
    order: Order.DESC,
    isDate: true,
    field: 'most_recent_qualifying_message_date',
  },
  {
    label: getText('oldest'),
    value: 'oldest',
    order: Order.ASC,
    isDate: true,
    field: 'most_recent_qualifying_message_date',
  },
  {
    label: getText('a-z'),
    value: 'a-z',
    order: Order.ASC,
    isDate: false,
    field: 'first_name',
  },
];

export const messageMock = {
  _id: 'id',
  user: { _id: 'id' },
  text: '',
  createdAt: new Date(),
};

// Modal
export const ERROR_MODAL_SELECT_FILES = 'error-modal-select-files';
export const ERROR_MODAL_MAX_FILES_COUNT_TEN = 'error-modal-max-files-count-10';

// Inbox
export const MESSAGE_LIMIT = 5000;
export const EMOJI_CHARS_LENGTH = 2;
export const CONVERSATIONS_TABS_WIDTH = '40%';
export const ERROR_NO_MESSAGE = 'error-no-message';
export const ERROR_MESSAGE_LIMIT_FIVE_THOUSAND =
  'error-message-limit-5-thousand';
export const ERROR_FILES_SENDING = 'error-files-sending';
export const ERROR_FILES_SENDING_MODAL = 'error-files-sending-modal';
export const LOADING_CONVERSATION_CREATING = 'loading-conversation-creating';

// ChatBox
export const ERROR_SELECT_FILES = 'error-select-files';
export const ERROR_MAX_FILES_COUNT_TEN = 'error-max-files-count-10';
export const LOADING_MESSAGES = 'loading-messages';
export const PHONE_PATTERN =
  /((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}/gi;
// Recognizes such phone number patterns:
// (604) 555-5555, +1 604 555 5555, 604 444-2222, 444-555-6666,
// +44 8984 1234, 0044 8984 1234, +1-234-567-8901, +46-234 5678901,
// 555-555-5555, 123 456 7890, +46-234 5678901, 250 555 0199,
// (250) 555-0199, 250-555-0199, 250-5550199, 250-555-0199, +12505550199

export const EXCLUDED_ERRORS_MODAL = [
  ERROR_SELECT_FILES,
  ERROR_MAX_FILES_COUNT_TEN,
  ERROR_FILES_SENDING,
];

export const EXCLUDED_ERRORS_INBOX = [
  ERROR_MODAL_SELECT_FILES,
  ERROR_MODAL_MAX_FILES_COUNT_TEN,
  ERROR_FILES_SENDING_MODAL,
];

export const TAKE = 30;
