import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { PHARMACY_API_BASE_URL, PHARMACY_ID } from '../common/constants';

import {
  BrandingDto,
  DefaultApi as PharmacyServiceClient,
  PharmacyDto,
  PharmacyPoliciesDto,
  DepartmentTimeRangeDto,
  PharmacyLocationFind200ResponseInner,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IPharmacyService {
  findAll(): Promise<PharmacyDto[]>;
  findLocationsByPharmacyId(
    pharmacyId: string,
    sortBy?: string,
    postalCode?: string,
    lat?: string,
    lng?: string,
    units?: string,
  ): Promise<PharmacyLocationFind200ResponseInner[]>;
  findPharmacyBranding(pharmacyId: string): Promise<BrandingDto>;
  findLocationHours(locationId: string): Promise<DepartmentTimeRangeDto[]>;
  findLocationServices(locationId: string): Promise<string[]>;
  findLocationLanguage(locationId: string): Promise<string[]>;
}

export class PharmacyService
  extends BaseApiService
  implements IPharmacyService
{
  private pharmacyServiceClient: PharmacyServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.pharmacyServiceClient = new PharmacyServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async findAll(): Promise<PharmacyDto[]> {
    const { data } = await this.pharmacyServiceClient.pharmacyFindAll();
    return data;
  }

  async findOne(pharmacyId: string): Promise<PharmacyDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyFindOne(pharmacyId);
    return data;
  }

  async findLocationsByPharmacyId(
    sortBy?: string,
    postalCode?: string,
    lat?: string,
    lng?: string,
    units?: string,
  ): Promise<PharmacyLocationFind200ResponseInner[]> {
    const { data } = await this.pharmacyServiceClient.pharmacyLocationFind(
      sortBy,
      postalCode,
      lat,
      lng,
      units,
    );
    return data;
  }

  async findPharmacyBranding(pharmacyId: string): Promise<BrandingDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyBrandingFindOne(pharmacyId);
    return data;
  }

  async findLocationHours(
    locationId: string,
  ): Promise<DepartmentTimeRangeDto[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationHoursFindAll(locationId);
    return data;
  }

  async findLocationServices(locationId: string): Promise<string[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationOptionsGetServices(
        locationId,
      );
    return data;
  }

  async findLocationLanguage(locationId: string): Promise<string[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationOptionsGetLanguages(
        locationId,
      );
    return data;
  }

  async findPoliciesForPharmacy(
    pharmacyId: string,
  ): Promise<PharmacyPoliciesDto> {
    const { data } =
      await this.pharmacyServiceClient.policyFindForPharmacy(pharmacyId);
    return data;
  }
}

export default new PharmacyService(
  PHARMACY_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  false,
);
