import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import pharmacyService from '../../../api/pharmacy-service';
import { LocationSearch } from '../../../types';
import { isZipCode } from '../utils';

// TODO: refactor in a better place
export const handleLocationDistanceSearch = async (search?: LocationSearch) => {
  const _isZipCode = isZipCode(search?.searchCriteria);

  try {
    if (!_isZipCode && !search?.latitude)
      throw 'You should provide a zip code or user location!';

    const locations = await pharmacyService.findLocationsByPharmacyId(
      'distance',
      _isZipCode ? search?.searchCriteria : undefined,
      !_isZipCode && search?.latitude ? `${search.latitude}` : undefined,
      !_isZipCode && search?.longitude ? `${search.longitude}` : undefined,
    );
    return locations as PharmacyLocationDto[];
  } catch (error) {
    // log error
    return [];
  }
};
