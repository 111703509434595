import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Image,
  Platform,
  useWindowDimensions,
  View,
  Linking,
  TouchableOpacity,
} from 'react-native';
import moment from 'moment';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { ListAccordion } from 'assets/components/list-accordion';
import { ListItemLink, ListMenu } from 'assets/components/list-menu';
const noImage = require('../../../assets/no_image.png');
const noVideo = require('../../../assets/no_video.png');
import { Button } from 'assets/components/button';
import { AlertCircleIcon, CloseIcon, MessageSquareIcon } from 'assets/icons';
import { notImplementedAlert } from 'assets/utils/alert';
import { Divider, IconButton } from 'react-native-paper';
import { getText } from 'assets/localization/localization';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import MedicationService, { Medication } from './MedicationService';
import { formatPhoneNumber } from '../../utils';
import { MedicationGuideDetails } from './MedicationGuideDetails';
import { EducationInfoSheet } from './EducationInfoSheet';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { Modal } from 'assets/components/modal';
import { CallIcon } from 'assets/icons/CallIcon';
import { BrightcoveVideoPlayer } from '../brightcove-video-player';

export const MedicationDetails: FunctionComponent<MedicationDetailsProps> = ({
  patient,
  prescription,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  const [medication, setMedication] = useState<Medication | undefined>();
  const sheetRef = React.useRef<BottomSheetModal>(null);
  const educationSheetRef = React.useRef<BottomSheetModal>(null);
  const [medicationModalIsOpen, setMedicationModalIsOpen] = useState(false);
  const [educationModalIsOpen, setEducationModalIsOpen] = useState(false);
  const { height } = useWindowDimensions();

  const isIntegrated =
    prescription.integration_type != 'Manual' && prescription.ndc != null;
  // TODO: review how to handle the other states:
  //    Request auto-refill, Requested, Yes, Not eligible, In Process
  const autoFillEnrolled = prescription.auto_fill_enrolled
    ? 'Auto-refill'
    : 'Request auto-refill';

  const dateOfBirth = moment(patient.date_of_birth, 'YYYY-MM-DD').format(
    'MM/DD/YYYY',
  );
  const lastRefillOrOrderDate = prescription.last_refill_date
    ? prescription.last_refill_date
    : prescription.last_order_date;
  const lastRefillOrOrderDateFormatted = lastRefillOrOrderDate
    ? moment(lastRefillOrOrderDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
    : '';

  const lastRefillOrOrderText = prescription.last_refill_date
    ? getText('last-filled-on')
    : getText('last-ordered-on');

  const writtenDate =
    prescription.written_date &&
    moment(prescription.written_date).format('MM/DD/YYYY');

  const handleOnPhoneNumberPress = () => {
    if (!prescription.doctor_phone) return;
    void Linking.openURL(`tel:${prescription.doctor_phone}`);
  };

  const handleOpenMedDetails = () => {
    Platform.OS === 'web'
      ? setMedicationModalIsOpen(true)
      : sheetRef.current?.present();
  };

  const handleOpenEducationDetails = () => {
    Platform.OS === 'web'
      ? setEducationModalIsOpen(true)
      : educationSheetRef.current?.present();
  };

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
    educationSheetRef.current?.dismiss();
  };

  const getMedicationInfo = async () => {
    const { medications } = await MedicationService.getMedicationInfo(
      prescription.ndc!,
    );
    setMedication(medications[0]);
  };

  const handleRefillPress = () => {
    // For the moment the flow is not implemented
    notImplementedAlert();
  };

  const handleMessagePress = () => {
    // For the moment the flow is not implemented
    notImplementedAlert();
  };

  const handleAutoRefillToggle = () => {
    // For the moment the flow is not implemented
    notImplementedAlert();
  };

  // Check if a value is defined. PS: 0 is a defined value
  const isDefined = (value: number | string | undefined | null) => {
    return !(value === undefined || value === null || value === '');
  };

  useEffect(() => {
    void getMedicationInfo();
  }, [prescription.ndc]);

  return (
    <View style={{ margin: theme.getSpacing(1) }}>
      <Form.Row>
        <Form.Column>
          <Text style={styles.labelStyle}>{getText('prescribed-for')}</Text>
        </Form.Column>
        <Form.Column>
          <Text style={styles.rightGreyText}>
            {patient.first_name} {patient.last_name}
          </Text>
        </Form.Column>
      </Form.Row>
      <Form.Row>
        <Form.Column></Form.Column>
        <Form.Column>
          <Text style={styles.rightGreyText}>
            {getText('date-of-birth-short')}: {dateOfBirth}
          </Text>
        </Form.Column>
      </Form.Row>
      <Form.Row>
        <Form.Column>
          <Text style={styles.labelStyle}>{getText('rx-number')}</Text>
        </Form.Column>
        <Form.Column>
          <Text style={styles.rightGreyText}>{prescription.rx_number}</Text>
        </Form.Column>
      </Form.Row>
      {lastRefillOrOrderDate && (
        <Form.Row>
          <Form.Column>
            <Text style={styles.labelStyle}>{lastRefillOrOrderText}</Text>
          </Form.Column>
          <Form.Column>
            <Text style={styles.rightGreyText}>
              {lastRefillOrOrderDateFormatted}
            </Text>
          </Form.Column>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Column>
          <Text style={styles.labelStyle}>{getText('auto-refills')}</Text>
        </Form.Column>
        <Form.Column>
          <TouchableOpacity
            onPress={handleAutoRefillToggle}
            disabled={prescription.auto_fill_enrolled}
          >
            <Text
              style={
                prescription.auto_fill_enrolled
                  ? styles.rightGreyText
                  : styles.rightPrimaryText
              }
            >
              {autoFillEnrolled}
            </Text>
          </TouchableOpacity>
        </Form.Column>
      </Form.Row>

      {isDefined(prescription.remaining_refills) && (
        <Form.Row>
          <Form.Column>
            <Text style={styles.labelStyle}>{getText('refills-left')}</Text>
          </Form.Column>
          <Form.Column>
            <Text style={styles.rightGreyText}>
              {prescription.remaining_refills}
            </Text>
          </Form.Column>
        </Form.Row>
      )}

      {isDefined(prescription.quantity_on_hand) && (
        <Form.Row>
          <Form.Column>
            <Text style={styles.labelStyle}>
              {getText('quantity-dispensed')}
            </Text>
          </Form.Column>
          <Form.Column>
            <Text style={styles.rightGreyText}>
              {prescription.quantity_on_hand}
            </Text>
          </Form.Column>
        </Form.Row>
      )}

      {isDefined(prescription.days_supply) && (
        <Form.Row style={{ marginBottom: theme.getSpacing(0.5) }}>
          <Form.Column>
            <Text style={styles.labelStyle}>{getText('days-supply')}</Text>
          </Form.Column>
          <Form.Column>
            <Text style={styles.rightGreyText}>{prescription.days_supply}</Text>
          </Form.Column>
        </Form.Row>
      )}

      {prescription.sig && (
        <Form.Row
          style={{
            marginLeft: theme.getSpacing(1),
            ...Platform.select({
              web: {
                marginLeft: undefined,
              },
            }),
          }}
        >
          <Form.Column>
            <ListAccordion
              title={getText('directions')}
              backgroundColor={theme.palette.white}
              titleStyle={{ ...styles.titleStyle, ...styles.labelStyle }}
              listStyle={styles.listStyle}
            >
              <Text style={{ color: theme.palette.gray[700] }}>
                {prescription.sig}
              </Text>
            </ListAccordion>
          </Form.Column>
        </Form.Row>
      )}

      {isIntegrated && (
        <Form.Row>
          <Form.Column>
            <Image
              source={
                medication && medication.images && medication.images.length > 0
                  ? { uri: medication.images[0].url }
                  : noImage
              }
              style={{
                width: 162,
                height: 119,
              }}
              resizeMode={'contain'}
            />
          </Form.Column>
        </Form.Row>
      )}
      <Form.Row style={{ marginBottom: theme.getSpacing(3) }}>
        <Form.Column>
          <Text style={styles.defaultText}>
            {getText('questions-about-medication')}
          </Text>
          <Text style={styles.defaultText}>
            {getText('ask-your-pharmacist')}
          </Text>
        </Form.Column>
      </Form.Row>
      <Form.Row>
        <Form.Column style={{ flex: 1 }}>
          <Button
            hierarchy={'secondary-gray'}
            logger={{
              id: 'medication-details-message-button',
            }}
            icon={MessageSquareIcon}
            style={{ width: 130 }}
            onPress={handleMessagePress}
          >
            {getText('message')}
          </Button>
        </Form.Column>
        <Form.Column style={{ flex: 2 }}>
          <Button
            hierarchy={'primary'}
            logger={{
              id: 'medication-details-refill-button',
            }}
            onPress={handleRefillPress}
            style={{ width: 132 }}
          >
            {getText('refill')}
          </Button>
        </Form.Column>
      </Form.Row>
      {isIntegrated && (
        <Form.Row>
          <Form.Column>
            <Text
              style={{
                fontWeight: 'bold',
                marginBottom: theme.getSpacing(2),
                marginTop: theme.getSpacing(4),
              }}
            >
              {getText('resources')}
            </Text>
            <Divider style={{ borderColor: theme.palette.gray[200] }} />
            <Image
              //TODO: Add actual medication image from Vuca here, this is just here as a placeholder
              source={noVideo}
              style={{
                width: 240,
                height: 200,
              }}
              resizeMode={'contain'}
            />
          </Form.Column>
        </Form.Row>
      )}
      {isIntegrated && (
        <Form.Row>
          <Form.Column>
            {medication && (
              <ListMenu>
                {medication.patient_educationsheet ? (
                  <ListItemLink onPress={handleOpenEducationDetails}>
                    <Text style={styles.boldText}>
                      {getText('patient-education-sheets')}
                    </Text>
                    <Text style={styles.guidesText}>
                      {getText('side-effects-interactions')}
                    </Text>
                  </ListItemLink>
                ) : (
                  <View>
                    <Text style={styles.boldText}>
                      {getText('patient-education-sheets')}
                    </Text>
                    <View style={styles.iconSpacing}>
                      <AlertCircleIcon size={20}></AlertCircleIcon>
                      <Text style={styles.guidesText}>
                        {getText('no-medication-guide')}
                      </Text>
                    </View>
                  </View>
                )}
                {medication.medication_guides ? (
                  <ListItemLink onPress={handleOpenMedDetails}>
                    <Text style={styles.boldText}>
                      {getText('medication-guides')}
                    </Text>
                    <Text style={styles.guidesText}>
                      {getText('how-to-take')}
                    </Text>
                  </ListItemLink>
                ) : (
                  <View style={{ marginTop: theme.getSpacing(2) }}>
                    <Text style={styles.boldText}>
                      {getText('medication-guides')}
                    </Text>
                    <View style={styles.iconSpacing}>
                      <AlertCircleIcon size={20}></AlertCircleIcon>
                      <Text style={styles.guidesText}>
                        {getText('no-medication-guide')}
                      </Text>
                    </View>
                  </View>
                )}
              </ListMenu>
            )}
          </Form.Column>
        </Form.Row>
      )}
      {writtenDate && (
        <Form.Row>
          <Form.Column>
            <Text style={styles.labelStyle}>
              {getText('prescription-written')}
            </Text>
          </Form.Column>
          <Form.Column>
            <Text style={styles.rightGreyText}>{writtenDate}</Text>
          </Form.Column>
        </Form.Row>
      )}
      {prescription.doctor_first_name && (
        <Form.Row>
          <Form.Column>
            <Text style={styles.labelStyle}>{getText('prescription-by')}</Text>
          </Form.Column>
          <Form.Column>
            <Text style={styles.rightGreyText}>
              {prescription.doctor_first_name} {prescription.doctor_last_name}
            </Text>
          </Form.Column>
        </Form.Row>
      )}
      {prescription.doctor_phone && (
        <Form.Row>
          <Form.Column>
            <Button
              hierarchy={'secondary-gray'}
              logger={{
                id: 'medication-details-call-prescriber-button',
              }}
              icon={CallIcon}
              style={{ ...styles.labelStyle, alignSelf: 'flex-start' }}
              onPress={handleOnPhoneNumberPress}
            >
              <Text style={styles.callPrescriber}>
                {getText('call-prescriber')}
                {formatPhoneNumber(prescription.doctor_phone)}
              </Text>
            </Button>
          </Form.Column>
        </Form.Row>
      )}
      <BottomSheet
        bottomSheetRef={sheetRef}
        enablePanDownToClose={false}
        height={'80%'}
        onDismiss={handleBottomSheetDismiss}
        children={<MedicationGuideDetails medication={medication} />}
        headerContent={
          <View
            style={{
              marginLeft: theme.getSpacing(1),
              marginRight: theme.getSpacing(1),
            }}
          >
            <View style={{ flexDirection: 'row', margin: theme.getSpacing(1) }}>
              <View style={styles.bottomSheetViewTitle}>
                <Text style={{ fontSize: 16, fontWeight: '600' }}>
                  {getText('medication-guides')}
                </Text>
              </View>
              <View style={{ justifyContent: 'flex-end' }}>
                <IconButton
                  accessibilityLabel={getText('dismiss')}
                  icon={CloseIcon}
                  onPress={handleBottomSheetDismiss}
                  size={20}
                  color={theme.palette.gray[500]}
                />
              </View>
            </View>
          </View>
        }
      />
      <Modal
        title={getText('medication-guides')}
        show={medicationModalIsOpen}
        okButtonProps={{
          onPress: () => setMedicationModalIsOpen(false),
          logger: { id: 'med-details-dismiss-button-modal' },
        }}
        dismissButtonProps={{
          onPress: () => setMedicationModalIsOpen(false),
          logger: { id: 'med-details-dismiss-button-modal' },
        }}
        isScrollable={true}
        height={height}
      >
        <MedicationGuideDetails medication={medication} />
      </Modal>
      <BottomSheet
        bottomSheetRef={educationSheetRef}
        height={'80%'}
        onDismiss={handleBottomSheetDismiss}
        enablePanDownToClose={false}
        children={<EducationInfoSheet medication={medication} />}
        headerContent={
          <View
            style={{
              marginLeft: theme.getSpacing(1),
              marginRight: theme.getSpacing(1),
            }}
          >
            <View style={{ flexDirection: 'row', margin: theme.getSpacing(1) }}>
              <View style={styles.bottomSheetViewTitle}>
                <Text style={{ fontSize: 16, fontWeight: '600' }}>
                  {getText('patient-education-sheets')}
                </Text>
              </View>
              <View style={{ justifyContent: 'flex-end' }}>
                <IconButton
                  accessibilityLabel={getText('dismiss')}
                  icon={CloseIcon}
                  onPress={handleBottomSheetDismiss}
                  size={20}
                  color={theme.palette.gray[500]}
                />
              </View>
            </View>
          </View>
        }
      />
      <Modal
        title={getText('patient-education-sheets')}
        show={educationModalIsOpen}
        okButtonProps={{
          onPress: () => setEducationModalIsOpen(false),
          logger: { id: 'med-details-dismiss-button-modal' },
        }}
        dismissButtonProps={{
          onPress: () => setEducationModalIsOpen(false),
          logger: { id: 'med-details-dismiss-button-modal' },
        }}
        isScrollable={true}
        height={height}
      >
        <EducationInfoSheet medication={medication} />
      </Modal>
    </View>
  );
};

export type MedicationDetailsProps = {
  patient: LocationPatientRecordDto;
  prescription: PrescriptionDto;
};

export type VideoDetailProp = {
  account_id: string;
  video_id: string;
  player_id: string;
};

const useStyles = makeStyles((theme) => ({
  listStyle: {
    margin: 0,
    marginRight: -(theme.getSpacing(1) + theme.getSpacing(0.5)),
    marginLeft: -theme.getSpacing(1),
  },
  titleStyle: {
    marginStart: -theme.getSpacing(1),
    marginEnd: -theme.getSpacing(1),
    paddingLeft: 0,
  },
  labelStyle: {
    fontFamily: 'Lato_700Bold',
    fontWeight: '700',
    lineHeight: 20,
    fontSize: 14,
  },
  bottomSheetViewTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  rightGreyText: {
    textAlign: 'right',
    color: theme.palette.gray[700],
  },
  rightPrimaryText: {
    textAlign: 'right',
    color: theme.palette.primary[600],
  },
  defaultText: {
    lineHeight: 20,
    fontSize: 16,
  },
  guidesText: {
    color: theme.palette.gray[600],
    fontSize: 14,
    marginBottom: theme.getSpacing(1),
    marginLeft: theme.getSpacing(0.5),
  },
  boldText: {
    color: theme.palette.gray[900],
    fontSize: 16,
  },
  iconSpacing: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginTop: theme.getSpacing(0.5),
  },
  callPrescriber: {
    alignSelf: 'flex-start',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 20,
    textAlign: 'left',
  },
}));
