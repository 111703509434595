import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { getSpacing, useTheme } from 'assets/theme';
import {
  SafeAreaView,
  ScrollView,
  View,
  StyleProp,
  ViewStyle,
  useWindowDimensions,
  Platform,
} from 'react-native';
import { Text } from 'assets/components/text';
import Constants from 'expo-constants';
import { getText } from '../../localization/localization';

export interface ScreenContainerHandler {
  scrollTo?(
    y?:
      | number
      | {
          x?: number | undefined;
          y?: number | undefined;
          animated?: boolean | undefined;
        },
    x?: number,
    animated?: boolean,
  ): void;
}

export const ScreenContainer = forwardRef<
  ScreenContainerHandler,
  ScreenContainerProps
>(
  (
    { children, backgroundColor, fullWidth = false, style, showVersion = true },
    ref,
  ) => {
    const theme = useTheme();
    const scrollViewRef = useRef<ScrollView>(null);
    const { width } = useWindowDimensions();
    let maxWidth = width;
    if (Platform.OS === 'web' && width > theme.webMaxWidth) {
      // let native use as much width as possible but let's limit web since it can get super duper big
      maxWidth = theme.webMaxWidth;
    }

    // Can't seem to find a css solution, so let's ensure our inner most screen container
    // takes up 100% of the possible screen area so we can bottom align buttons if desired
    // without children needing to find hacks
    // Screen Height is the height of the screen without header or bottom tabs
    const [screenHeight, setScreenHeight] = useState(null as null | number);

    useImperativeHandle(ref, () => ({
      scrollTo: scrollViewRef.current?.scrollTo,
    }));

    return (
      <SafeAreaView
        style={[
          {
            flex: 1,
            height: '100%',
            backgroundColor: backgroundColor ?? theme.palette.white,
          },
          style,
        ]}
        onLayout={(event) => {
          var { height } = event.nativeEvent.layout;
          setScreenHeight(height);
        }}
      >
        <ScrollView
          style={{
            flex: 1,
          }}
          ref={scrollViewRef}
        >
          <View
            style={[
              {
                width: maxWidth + (fullWidth ? 0 : -theme.getSpacing(2)),
                alignSelf: 'center',
                marginBottom: theme.getSpacing(2),
              },
              !!screenHeight && {
                minHeight: screenHeight - theme.getSpacing(2),
              },
            ]}
          >
            {showVersion && (
              <Text
                style={{
                  alignSelf: 'center',
                  color: '#D3D3D3',
                  padding: getSpacing(1),
                }}
              >
                {getText('version')}: {Constants.manifest?.version}
              </Text>
            )}
            {children}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  },
);

export interface ScreenContainerProps {
  backgroundColor?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  children?: React.ReactNode;
  showVersion?: boolean;
}
