import React, { FunctionComponent, PropsWithChildren } from 'react';
import theme, { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AccountStackParamList } from '../AccountNavigation';
import { ScreenContainer } from 'assets/layout';
import Constants from 'expo-constants';
import { getText } from 'assets/localization/localization';
import { View } from 'react-native';

export const AboutApp: FunctionComponent<
  PropsWithChildren<AboutAppProps>
> = () => {
  const styles = useStyles();
  const version = Constants.manifest?.version;

  if (!version) {
    throw new Error('Unable to load version from manifest');
  }

  return (
    <ScreenContainer>
      <View style={{ marginLeft: theme.getSpacing(1) }}>
        <Text style={styles.containerText}>{`${getText(
          'version',
        )}: ${version}`}</Text>
        <Text style={styles.containerText}>
          {getText('copyright-lumistry')}
        </Text>
        <Text style={styles.containerText}>
          {getText('all-rights-reserved')}
        </Text>
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  containerText: {
    color: theme.palette.gray[700],
    fontSize: 18,
    lineHeight: 28,
    fontWeight: '400',
    textAlign: 'left',
  },
}));

type AboutAppProps = NativeStackScreenProps<AccountStackParamList, 'about-app'>;
