import React, { FunctionComponent, useEffect } from 'react';
import { Platform, View } from 'react-native';
import { Text } from 'assets/components/text';
import { ScreenContainer } from 'assets/layout';
import { useAppStateStore } from '../../store/app-store';
import { Icon } from 'assets/components/icon';
import { LocationIcon, NewMessageIcon } from 'assets/icons';
import { LandingHeader } from '../../components/landing-header';
import { Button } from 'assets/components/button';
import { makeStyles, useTheme } from 'assets/theme';
import { NewMessageHandler } from './MessageProps';
import { NewMessage } from './NewMessage';
import { MessagesEmptyState } from './MessagesEmptyState';
import { getText } from 'assets/localization/localization';
import { ConversationItem } from './ConversationItem';
import { useMessagesState } from './messages-store';
import {
  setShowMessageModal,
  setViewedConversations,
  buildMessageList,
} from './messages-actions';
import { useSockets } from './useSockets';
import { useUserState } from '../../store/user-store';

export const Messages: FunctionComponent = () => {
  const { pharmacyName } = useAppStateStore();
  const { user } = useUserState();
  const theme = useTheme();
  const newMessageRef = React.useRef<NewMessageHandler>(null);
  const styles = useStyles();
  const { showModal, rawConversations, viewedConversations } =
    useMessagesState();
  useSockets();

  useEffect(() => {
    if (showModal) {
      handleShowNewMessageModal();
    }
  }, [showModal]);

  const handleShowNewMessageModal = () => {
    newMessageRef.current?.show();
    setShowMessageModal(false);
  };

  const onMessageCreate = async () => {
    await buildMessageList();
  };

  useEffect(() => {
    void (async () => {
      await buildMessageList();
    })();
  }, [user?.preferredPharmacyLocationId]);

  useEffect(() => {
    rawConversations.sort((a, b) => {
      return (
        new Date(b.most_recent_qualifying_message_date).valueOf() -
        new Date(a.most_recent_qualifying_message_date).valueOf()
      );
    });
    const newViewedConversations = rawConversations
      .filter((conversation) => conversation.patient_viewed_all_messages)
      .map((conversation) => conversation.conversation_id);

    setViewedConversations(newViewedConversations);
  }, [rawConversations]);

  const NewMessageButton = () => {
    return rawConversations.length !== 0 ? (
      <View style={Platform.OS !== 'web' && styles.buttonContainerMobile}>
        <Button
          hierarchy={'primary'}
          logger={{
            id: 'new-message-modal-button',
            data: undefined,
          }}
          icon={NewMessageIcon}
          onPress={handleShowNewMessageModal}
        >
          {getText('new-message')}
        </Button>
      </View>
    ) : null;
  };

  return (
    <View style={styles.container}>
      {Platform.OS !== 'web' && (
        <LandingHeader
          pharmacyName={pharmacyName}
          greetings={getText('messages')}
        />
      )}
      <ScreenContainer showVersion={false}>
        {rawConversations.length === 0 && (
          <MessagesEmptyState onMessageCreate={onMessageCreate} />
        )}
        {rawConversations.length !== 0 ? (
          <>
            <View style={styles.locationContainer}>
              <Text style={styles.label}>
                {getText('you-are-chatting-with')}
              </Text>
              <View style={styles.pharmacyNameContainer}>
                <Icon
                  icon={LocationIcon}
                  size={16}
                  color={theme.palette.gray[500]}
                />
                <Text style={styles.pharmacyName}>{pharmacyName}</Text>
              </View>
            </View>
            <NewMessage ref={newMessageRef} onMessageCreate={onMessageCreate} />
            <View>
              {rawConversations.map((conversation) => (
                <ConversationItem
                  key={conversation.conversation_id}
                  conversation={conversation}
                  isViewed={viewedConversations.includes(
                    conversation.conversation_id,
                  )}
                />
              ))}
              {Platform.OS === 'web' ? <NewMessageButton /> : null}
            </View>
          </>
        ) : null}
      </ScreenContainer>
      {Platform.OS !== 'web' ? <NewMessageButton /> : null}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  locationContainer: {
    marginTop: theme.getSpacing(2),
    marginLeft: theme.getSpacing(1),
  },
  pharmacyNameContainer: {
    flexDirection: 'row',
  },
  pharmacyName: {
    color: theme.palette.gray[500],
  },
  label: {
    color: theme.palette.gray[500],
    marginBottom: theme.getSpacing(0.5),
  },
  buttonContainerMobile: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    width: '50%',
    height: 50,
  },

  button: { fontSize: 8 },
}));
