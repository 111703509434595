import React, { FunctionComponent, PropsWithChildren } from 'react';
import { getSpacing, useTheme } from 'assets/theme';
import {
  View,
  StyleProp,
  ViewStyle,
  useWindowDimensions,
  Platform,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Text } from 'assets/components/text';
import Constants from 'expo-constants';
import { getText } from '../../localization/localization';

export const ScreenSafeContainer: FunctionComponent<
  PropsWithChildren<ScreenSafeContainerProps>
> = ({
  children,
  backgroundColor,
  fullWidth = false,
  style,
  showVersion = true,
}) => {
  const theme = useTheme();

  const { top, bottom, left, right } = useSafeAreaInsets();
  const { width } = useWindowDimensions();
  let maxWidth = width;
  if (Platform.OS === 'web' && width > theme.webMaxWidth) {
    // let native use as much width as possible but let's limit web since it can get super duper big
    maxWidth = theme.webMaxWidth;
  }

  return (
    <View
      style={[
        {
          flex: 1,
          backgroundColor: backgroundColor ?? theme.palette.white,
          width: maxWidth + (fullWidth ? 0 : -theme.getSpacing(2)),
          paddingTop: top,
          paddingBottom: bottom,
          paddingLeft: left,
          paddingRight: right,
          alignSelf: 'center',
        },
        style,
      ]}
    >
      {showVersion && (
        <Text
          style={{
            alignSelf: 'center',
            color: '#D3D3D3',
            padding: getSpacing(1),
          }}
        >
          {getText('version')}: {Constants.manifest?.version}
        </Text>
      )}
      {children}
    </View>
  );
};

export interface ScreenSafeContainerProps {
  backgroundColor?: string;
  fullWidth?: boolean;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  children?: React.ReactNode;
  showVersion?: boolean;
}
