import React from 'react';
import {
  View,
  Platform,
  useWindowDimensions,
  TouchableOpacity,
} from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { PhoneCallIcon } from 'assets/icons';
import { MapSize, StoreInformationProps } from './types';
import { Linking } from 'react-native';
import { Map, MapProps } from 'assets/components/map';
import { formatPhoneNumber } from '../../utils';

export const StoreInformation: React.FunctionComponent<
  StoreInformationProps
> = ({
  item,
  onChangePress,
  mapProps,
  disabled = false,
  showChange: canChange,
  showPhone = true,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  const isWeb = Platform.OS === 'web';
  const isXsMap = mapProps?.size === MapSize.xs;

  const handleOnChangePress = () => {
    onChangePress(item);
  };

  const handleOnPhoneNumberPress = () => {
    Linking.openURL(`tel:${item.phone}`);
  };

  const { width } = useWindowDimensions();
  const mapWidth = (isWeb ? theme.webMaxWidth : width) - theme.getSpacing(2);

  const mapSizeConfig: Record<
    MapSize,
    Omit<MapProps, 'longitude' | 'latitude' | 'accessToken'>
  > = {
    md: { height: 200, width: mapWidth },
    sm: { height: 90, width: mapWidth },
    xs: { height: 90, width: 100 },
  };

  return (
    <View>
      <View
        style={{
          flexDirection: isXsMap ? 'row' : 'column',
        }}
      >
        {mapProps && item.latitude && item.longitude && (
          <Map
            {...mapProps}
            latitude={item.latitude}
            longitude={item.longitude}
            {...(mapProps.size ? mapSizeConfig[mapProps.size] : {})}
          />
        )}
        <View style={[isXsMap && styles.leftSpacing, { flex: 1 }]}>
          <View style={styles.topSpacing}>
            <Text style={styles.selectedAddressTitle}>{item.name}</Text>
          </View>
          {item.address && (
            <View>
              <Text style={styles.selectedAddressSubTitle} numberOfLines={1}>
                {`${item.address.address1}, ${item.address.city}, ${item.address.state} ${item.address.postal_code}`}
              </Text>
            </View>
          )}
          <View
            style={[
              styles.bottomContainer,
              styles.topSpacing,
              !canChange && { flexDirection: 'row-reverse' },
            ]}
          >
            {canChange === true && (
              <TouchableOpacity onPress={handleOnChangePress}>
                <Text style={[styles.pressableText, isWeb && styles.pointer]}>
                  {getText('store-selector-change')}
                </Text>
              </TouchableOpacity>
            )}
            {item.phone && showPhone && (
              <TouchableOpacity
                onPress={handleOnPhoneNumberPress}
                style={styles.phoneContainer}
              >
                <PhoneCallIcon color={theme.palette.primary[500]} size={17} />
                <Text
                  style={[
                    styles.pressableText,
                    styles.leftSpacing,
                    isWeb && styles.pointer,
                  ]}
                >
                  {formatPhoneNumber(item.phone)}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  selectedAddressTitle: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[900],
  },
  selectedAddressSubTitle: {
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  topSpacing: { marginTop: theme.getSpacing(1) },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pressableText: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.primary[500],
  },
  phoneContainer: { flexDirection: 'row', alignItems: 'center' },
  pointer: {
    //@ts-ignore
    [Platform.OS === 'web' ? 'cursor' : undefined]: 'pointer', // only for web purposes
  },
}));
